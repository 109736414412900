import React from 'react'
import Navbar from '../Navbar/Navbar'
import FooterTwo from '../FooterTwo/FooterTwo'
import "./terms.css";


export default function Terms() {
  <style>
    
  </style>
  return (
    <>
    <Navbar />
    <div className="termDiv" style={{padding:'0px 80px'}}>
    <h1 style={{textAlign:'center', fontSize:'40px',margin:'20px'}}>Terms & Conditions</h1>
    <p style={{fontWeight:'bold'}}>
      This document is an electronic record in terms of Information Technology
      Act, 2000 and rules there under as applicable and the amended provisions
      pertaining to electronic records in various statutes as amended by the
      Information Technology Act, 2000. This electronic record is generated by a
      computer system and does not require any physical or digital
      signatures.<br />
      <br />
      This document is published in accordance with the provisions of Rule 3 (1)
      of the Information Technology (Intermediaries guidelines) Rules, 2011 that
      require publishing the rules and regulations, privacy policy and Terms of
      Use for access or usage of a computer resource.
    </p>

    <p>
      THESE TERMS OF USE (THE &quot;TERMS OF USE&quot;) ARE ISSUED BY HELTHOFIT
      PRIVATE LIMITED AND ITS DIRECTLY AND INDIRECTLY HELD COMPANIES
      (HEREINAFTER, &ldquo;PAYBYCAL&rdquo;). THEY APPLY TO ALL WEBSITES,
      BLOGGING SITES, MOBILE APPLICATIONS AND OTHER ONLINE DIALOGUE PLATFORMS
      OWNED OR MANAGED BY OR ON BEHALF OF PAYBYCAL UNDER THE URL
      WWW.PAYBYCAL.COM<br />
      <br />
      By accessing the Paybycal Website/Mobile Application and any page thereof,
      you agree to be bound by these terms of use and to comply with any
      applicable laws and regulations. If you do not agree to these Terms of
      Use, do not proceed to further pages of the Paybycal.<br />
      <br />
      Paybycal reserves the right to vary or amend these Terms of Use at any
      time and Paybycal is under no obligation to inform you about such
      variations or amendments. The most recent version of the Terms of Use will
      govern the services on Paybycal Website/Mobile Application and will always
      be available on the Paybycal Website/Mobile Application. By continuing to
      access or use this Paybycal Website/Mobile Application after any changes
      become effective, you agree to be bound by the revised Terms of Use.
      Please visit these Terms of Use on a regular basis to ensure that you have
      read the latest version. These Terms of Use were updated the last time on
      the date indicated at the end of the Terms of Use.<br />
      <br />
      The headings used for each of the paragraphs of these Terms of Use do not
      affect its interpretation. For the purpose of these Terms of Use,
      &ldquo;Content&rdquo; means any text, written work, software, database,
      format, graphic, image, photo, video clip, podcast, listing or any other
      information or material which appears on or forms part of a Paybycal
      Website/Mobile Application.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Company Information and Mobile application Operation
    </p>

    <p>
      Paybycal is established with the intent of providing reward system for
      completing set target according to fitness goal set by user itself along
      with an e-commerce. The user can create his profile by adding his details
      and choosing his preferences, with the help of applications algorithm it
      provides a set target to achieve his fitness goals. There are various
      tools to track, log with exercises and nutrition. Users get rewarded with
      silver calcoin (virtual coin, 1 calcoin is equivalent to 1INR) and these
      coins can be redeemed in discounts while purchasing products from store or
      purchasing plans from the experts only on Paybycal App.<br />
      &nbsp;
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      CALCOIN TERMS AND CONDITIONS
    </p>

    <p>
      1. You may avail the services of the Calcoin upon successful registration
      and creation of an Account on the Paybycal App or Website. You are bound
      by these terms and conditions of the Calcoin System.
    </p>

    <p>
      2. By signing up, you agree that you have read and understood the Terms
      and Conditions that govern the Calcoin System, and give consent to the
      Company to contact you for events, promotions, product information and
      discount. This will override the registry on NDNC/DND.
    </p>

    <p>
      3. Users can benefit from the Calcoin System by availing the services on
      the Paybycal App or Website.
    </p>

    <p>
      4. The Calcoin System is open to users of Paybycal worldwide above 18
      years of age only.
    </p>

    <p>
      5. Calcoin System is for individuals only - it is not open to corporates
      or companies.
    </p>

    <p>
      6. Enrolment must be in the applicant&#39;s full name and mobile number is
      mandatory. Only one enrollment per individual will be acknowledged.
    </p>

    <p>
      7. Calcoin balance is not transferable. The person named database must be
      present at the time of purchase to be eligible for Calcoin available as
      per the System.
    </p>

    <p>
      8. Misuse of Calcoin or other System benefits may result in termination of
      membership or withdrawal of benefits at the sole discretion of Company
    </p>

    <p>
      9. No accumulation or redemption of Calcoin will be permissible if, on
      relevant date, the Paybycal Account has been deleted or is liable to be
      deleted or if the account of the User is a defaulted account or if there
      is any breach of any clause of these Terms and Conditions.
    </p>

    <p>
      10. Company reserves the right to refuse Calcoin System to an applicant
      without assigning any reason whatsoever.
    </p>

    <p>
      11. Company may suspend or terminate the Refer and Earn program or any
      user&#39;s ability to participate in the program at any time for any
      reason at their sole discretion.
    </p>

    <p>
      12. Usage conditions of Calcoin may change at the discretion of Company,
      at any point in time.
    </p>

    <p>
      13. The Company reserves the right to amend these terms and conditions at
      any time without any prior notice. Modifications of these terms will be
      effective from the time they are updated in the Terms and Conditions
      section.
    </p>

    <p>
      14. A product purchased by redemption of Calcoin from Paybycal Store is
      governed by and subject to the applicable Seller policies, including
      applicable exchange and shipping policies. You agree that we are not
      agents of any Seller and that the Sellers operate independently and are
      not under our control. Accordingly, your participation in offers or
      promotions of, or correspondence with, any Seller is solely between you
      and that Seller. We do not assume any liability, obligation or
      responsibility for any part of such correspondence, offer or promotion,
      including, without limitation, the withdrawal or modification of any such
      offer or promotion. Company is not responsible for changes to, or
      discontinuance of, any Seller, or Seller withdrawal from the Program, or
      for any effect on accrual of Calcoin caused by such changes,
      discontinuance or withdrawal<br />
      &nbsp;
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Earning Calcoin</p>

    <p>1. A user can earn Silver Calcoin by-</p>

    <p style={{marginLeft:'40px'}}>a. completing 8000 Steps on Paybycal.</p>

    <p style={{marginLeft:'40px'}}>
      b. completing Calorie Intake Target on Paybycal.
    </p>

    <p style={{marginLeft:'40px'}}>
      c. completing Calorie Burn Target on Paybycal.
    </p>

    <p style={{marginLeft:'40px'}}>d. Body Weight update on Paybycal.</p>

    <p style={{marginLeft:'40px'}}>
      e. referring and registering new users on Paybycal.
    </p>

    <p style={{marginLeft:'40px'}}>
      f. leaving a review of the Paybycal Mobile Application on Google Play
      Store or Apple App Store.
    </p>

    <p>
      2. Gold Calcoin can be purchased by users in Paybycal App only. (1 gold
      calcoin is equivalent to 1INR)
    </p>

    <p>
      3. Silver Calcoin are rewards awarded to users of the app which can be
      redeemed for product discounts listed on Paybycal Store.
    </p>

    <p>
      4. Cashback are rewarded in Gold Calcoin on adding Money to wallet and
      completing purchases through wallet on Paybycal App.
    </p>

    <p>
      5. The receipt of Calcoin or it&rsquo;s redemption by the end user should
      not be linked to money paid for the purchase of services on the Platform.
      The Company hereby clarifies that Calcoin are being granted by us at our
      discretion only.
    </p>

    <p>
      6. By referring Paybycal to your friends, both user and users&rsquo;
      friend will receive 500 Silver Calcoin each upon successful registration
      by users&rsquo; friend on Paybycal. Users&rsquo; friend must not have
      installed the Paybycal app on any of their devices before. The Email ID
      and/or phone number through which the friend signs up with Paybycal app,
      must not have been used for signing up with Paybycal earlier. The mobile
      number provided by the friend to receive the OTP must not have been used
      to sign up earlier. The device on which the user&rsquo;s friend downloads
      the Paybycal app should not be rooted or jail-broken. Paybycal app should
      not be installed using App runtime for Chrome, emulators or simulators.
    </p>

    <p>
      7. If your friends use someone else&#39;s referral link, the person whose
      link was used first to download the Paybycal App would get benefits, even
      though you had referred them first. The first link used to install the app
      by your friend would be considered for the referral Calcion. The Company
      reserves all rights to change the amounts conferred under the Refer and
      Earn program at any point in time.
    </p>

    <p>
      8. Calcoin cannot be transferred to your bank account. Additionally,
      Calcoin across multiple accounts cannot be combined into a single account.
      Calcoin cannot be exchanged for cash.
    </p>

    <p>9. Users can refer to any number of people.</p>

    <p>
      10. Calcoin earned by a user will be credited to his/her account within
      the next 24 hours.
    </p>

    <p>
      11. On redemption, the Calcoin so redeemed would be automatically
      subtracted from the accumulated Calcoin in your Paybycal wallet.
    </p>

    <p>
      12. Calcoin can only be earned through the use of Paybycal App or Website.
    </p>

    <p>
      13. No two offers or discounts available can be clubbed together unless
      specified otherwise.
    </p>

    <p>
      14. Your unique referral links/code should only be used for personal and
      non-commercial purposes. You cannot distribute/publish your unique
      referral link/code where there is no reasonable basis for believing that
      most of the recipients are personal friends (such as coupon websites,
      forums, Wikipedia etc.).
    </p>

    <p>
      15. Calcoin earned as a result of fraudulent activities will be revoked
      and deemed invalid.
    </p>

    <p>
      16. In case the Calcoin are not credited to your account, please write to
      support@paybycal.com
    </p>

    <p></p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Paybycal Store Terms
    </p>

    <p>
      1. All Products listed for sale at the Paybycal Store are subject to
      availability and supplier restrictions at the time of purchase. The
      Products listed in the Paybycal Store are subject to change without any
      prior notice.
    </p>

    <p>
      2. Products listed on Paybycal Store are meant for end-consumers only (and
      not resale). We may refuse to service your order if we suspect that you
      are buying Products for resale.
    </p>

    <p>
      3. Company may cancel or refuse your order, or limit the quantity of
      Products stated in your order, at its sole discretion. Company may also
      require further information from you before confirming or processing your
      order.
    </p>

    <p>
      4. The price of Products is displayed in Indian Rupees. It may exclude
      delivery charges, postage and handling charges, conveyance charges, and/or
      applicable taxes. However, the total price of Products (including
      voluntary and involuntary charges) will be shown to you and your consent
      will be taken before confirming any order.
    </p>

    <p>
      5. We endeavour to deliver purchased Products to you as soon as possible,
      as per the indicative delivery schedule shown on our Mobile
      Application/Website. However, the actual delivery times may vary, and
      depend on many factors beyond our control (like area/PIN code of delivery,
      processing of shipments by our logistics vendors, availability of
      transport services, or any other factors not within our control). We
      assume no liability if Products are delivered after the indicative
      delivery schedule.
    </p>

    <p>
      6. Title in Products bought from the Paybycal Store will pass to you upon
      delivery of the Products to our transport carrier. However, the risk of
      loss or damage to Products will pass to you upon delivery of the Products
      at your submitted address.
    </p>

    <p>
      7. Company does not accept liability for damage to persons or property
      whatsoever caused in relation to Products bought by Paybycal Users through
      Paybycal Store.
    </p>

    <p>
      8. Company will not be liable or responsible for the Products offered
      through the Paybycal Store and Company gives no warranty (whether express
      or implied) or representation either express or implied with respect to
      type, quality or fitness of goods acquired or their suitability for any
      purpose, whatsoever.
    </p>

    <p>
      9. If a Product delivered to you has obvious damage upon receipt, or is
      not the Product you ordered, you can request an exchange/replacement from
      Company, as long as you make your request within 7 working days of the
      date of receipt of the Product. We may contact you to confirm Product
      damage or to identify the product delivered to you, before confirming
      exchange/replacement. Please ensure that you retain products in condition
      The product packaging, price tags, supporting accessories and information
      booklets/brochure to allow us to process an exchange/replacement.
    </p>

    <p>
      10. You are responsible to determine if the Product you purchase is
      compatible with other equipment (if such other equipment is required). You
      acknowledge that an absence of compatibility is not a defect in the
      Product permitting you to exchange/return it.
    </p>

    <p>
      11. Any images displayed on the Paybycal Store for Products are for
      illustrative purposes only. Characteristics of actual Products may vary.
    </p>

    <p>
      You may be required to purchase or pay a fee to access some of our
      services. You agree to provide current, complete, and accurate purchase
      and account information for all purchases made via the Website/Mobile
      Application. You further agree to promptly update account and payment
      information, including email address, payment method, and payment card
      expiration date, so that we can complete your transactions and contact you
      as needed. We bill you through an online billing account for purchases
      made via the Website/Mobile Application. GST will be added to the price of
      purchases as deemed required by us. We may change prices at any time. All
      payments shall be in Indian National Rupees.<br />
      <br />
      You agree to pay all charges or fees at the prices then in effect for your
      purchases, and you authorize us to charge your chosen payment provider for
      any such amounts upon making your purchase.<br />
      <br />
      We reserve the right to correct any errors or mistakes in pricing, even if
      we have already requested or received payment. We also reserve the right
      to refuse any order placed through the Website/Mobile Application. We use
      advanced encryption technology, consistent with industry practice, to
      protect your payment information. All payments on the Platform are
      processed through secure and trusted payment gateways, managed by leading
      banks or service providers. We understand that banks use the 3D secure
      password service for online transactions, adding another security
      layer.<br />
      <br />
      Any accepted refunds/chargebacks will be routed back to the payer in his
      wallet on Paybycal.
    </p>

    <p></p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Services and Solutions
    </p>

    <p></p>

    <p>
      Paybycal makes no guarantee, representation or warranty that all
      activities described on Paybycal Website/Mobile Application are
      appropriate or suitable to your health. Therefore, please read carefully
      the description on the Paybycal Site and mobile application or request
      further information about the relevant activity.<br />
      <br />
      In consideration of being allowed to participate in the activities and
      structured diet programs provided in articles offered by Paybycal, and to
      use its structured diet programs and training schedule, you do hereby
      waive, release and forever discharge and hold harmless Paybycal and its
      consultants, officers, agents, and employees from any and all
      responsibility, liability, cost and expenses, including injuries or
      damages, resulting from my participation in any activities, or use of any
      structured diet programs designed by Paybycal. You do also hereby release
      Paybycal its consultants, officers, agents and employees from any
      responsibility or liability for any injury, damage or disorder (physical,
      metabolic, or otherwise) or in any way arising out of or connected with my
      participation in any activities with Paybycal.<br />
      <br />
      You understand and you are aware that strength, flexibility, and aerobic
      exercise, including the use of equipment are a potentially hazardous
      activity. You also understand that fitness activities involve a risk of
      injury and even death, and that you are voluntarily participating in these
      activities and using equipment and machinery with knowledge of the risk
      involved. You hereby agree to expressly assume and accept any and all
      risks of injury or death related to said fitness activities. In addition,
      you certify that you are 18 years of age or older. You do hereby further
      declare yourself to be physically sound and suffering from no condition,
      impairment, disease, infirmity or other illness that would affect nutrient
      metabolism or prevent your participation or use of equipment or machinery
      except as hereinafter stated.<br />
      <br />
      And you also agree that if you are suffering from any ailment or any
      medical condition you have to inform and produce relevant documents to
      Paybycal prior to using the application yourself. You do hereby
      acknowledge that Paybycal has recommended you to obtain a
      physician&rsquo;s approval for your participation in an exercise/fitness
      activity or in the use of exercise equipment and machinery. You also
      acknowledge that Paybycal has recommended that you have a yearly or more
      frequent physical examination and consultation with your physician as to
      physical activity, exercise and use of exercise and training equipment so
      that you might have his/her recommendations concerning these fitness
      activities and use of equipment. You acknowledge that you have either had
      a physical examination and been given your physician&rsquo;s permission to
      participate, or that you have decided to participate in activity and use
      of equipment, machinery, and programs provided in articles by Paybycal
      without the approval of your physician and do hereby assume all
      responsibility for your participation and activities, and utilization of
      equipment and machinery in your activities.<br />
      <br />
      Paybycal diet/training articles are not meant to treat or manage any
      health condition. Always consult with your healthcare provider prior to
      adjusting your current style of eating or beginning any new diet and/or
      training plan. You understand that you are agreeing to the terms of the
      Company i.e., Paybycal having known that beforehand and understand that
      not following the instructions and structured diet programs given in
      Paybycal articles or made by yourself or your coach and nutritionist
      entirely and regularly given will not produce 100% result.
    </p>

    <p></p>

    <p></p>

    <p></p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      User Registration
    </p>

    <p>
      User may be required to register with the Website/Mobile Application. User
      agrees to keep his password confidential and will be responsible for all
      use of his account and password. Paybycal reserve the right to remove,
      reclaim, or change a username that would be selected by user if Paybycal
      determine, in its sole discretion, that such username is inappropriate,
      obscene, or otherwise objectionable.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Conduct of User</p>

    <p>
      1. User may post their reviews, ratings or comments and submit
      suggestions, ideas, questions, or other information, so long as the
      Contents are not illegal, obscene, threatening, defamatory, invasive of
      privacy, infringing of intellectual property rights, or otherwise
      injurious to third parties or objectionable and do not consist of or
      contain software viruses, political campaigning, commercial solicitation,
      chain letters, mass mailings, or any form of &quot;spam&quot;. User shall
      not use a false e-mail address, impersonate any person or entity, or
      otherwise mislead as to the origin of the Contents. However, user
      acknowledge that Paybycal does not pre-screen the Content, but that
      Paybycal and its designees shall have the right (but not the obligation)
      in their sole discretion to accept, refuse, move, or remove any Contents
      that are available via the website or social media platform.<br />
      <br />
      2. User understands that all Contents, whether publicly posted or
      privately transmitted, are the sole responsibility of the person from
      which such Content originated. This means that the user, and not Paybycal,
      is entirely responsible for all Content that the user has provided
      Paybycal with in order to upload, post, transmit or otherwise make
      available via the website or social media platform.<br />
      <br />
      3. User expressly agree not to use the Website/Mobile Application to: (i)
      harm any user in any manner either directly or indirectly; (ii) forge
      headers or otherwise manipulate identifiers in order to disguise the
      origin of any Contents transmitted through the website; (iii) act in a
      manner that negatively affects other user&#39;s ability to engage in real
      time exchanges or &quot;stalk&quot; or in any other manner harass another
      user; (iv) interfere with or disrupt the website or servers or networks
      connected to the website, or disobey any requirements, procedures,
      policies or regulations of networks connected to the website; (v) upload
      or transmit any material that is deemed derogatory with respect to the
      name, services or Contents relating to or provided by Paybycal; (vi)
      intentionally or unintentionally violate any applicable local, state,
      national or international laws;<br />
      <br />
      4. By using the Website/Mobile Application, user represent and warrant
      that: (i) All registration information that user submit will be true,
      accurate, current, and complete; (ii) User will maintain the accuracy of
      such information and promptly update such registration information as
      necessary; (iii) User have the legal capacity and user agree to comply
      with these Terms and Conditions; (iv) User is not a minor in the
      jurisdiction in which he resides; (v) User will not access the
      Website/Mobile Application through automated or non-human means, whether
      through a bot, script or otherwise;<br />
      <br />
      5. If user provide any information that is untrue, inaccurate, not
      current, or incomplete, Paybycal has the right to suspend or terminate
      user account and refuse any and all current or future use of the
      Website/Mobile Application (or any portion thereof).
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Eligibility</p>

    <p>
      You must be at least 18 to enrol with an Expert on the Platform. If you
      are below the ages of 18 (&ldquo;Minor&rdquo;), you may use our Services
      only with the supervision and consent of a parent or guardian. No
      individual under these age limits may use our Services, provide any
      Personal Data to Paybycal or otherwise submit Personal Data through the
      Services (e.g., a name, address, telephone number, or email address). As a
      minor if You wish to use Our Products or Services, such use shall be made
      available to You by Your legal guardian or parents, who has agreed to
      these Terms. In the event a minor utilizes the
      Application/Website/Services, it is assumed that he/she has obtained the
      consent of the legal guardian or parents and such use is made available by
      the legal guardian or parents. The Company will not be responsible for any
      consequence that arises as a result of misuse of any kind of Our Website
      or any of Our Products or Services that may occur by virtue of any person
      including a minor registering for the Services/Products provided. By using
      the Products or Services You warrant that all the data provided by You is
      accurate and complete and that the Minor using the Website has obtained
      the consent of parent/legal guardian (in case of minors). The Company
      reserves the right to terminate Your account and / or refuse to provide
      You with access to the Products or Services if it is discovered that You
      are under the age of 18 (eighteen) years and the consent to use the
      Products or Services is not made by Your parent/legal guardian or any
      information provided by You is inaccurate. You acknowledge that the
      Company does not have the responsibility to ensure that You conform to the
      aforesaid eligibility criteria. It shall be Your sole responsibility to
      ensure that You meet the required qualification. Any persons under the age
      of 18 (eighteen) should seek the consent of their parents/legal guardians
      before providing any Information about themselves or their parents and
      other family members on the Website.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Third Party Statements and Quotes
    </p>

    <p>
      The Website/Mobile Application may include statements and quotes of third
      parties, including service providers, relating to Paybycal and its
      business operations. Such statements and quotes are generally labeled as
      information provided by third parties. Paybycal does not and is under no
      obligation to verify the content of third-party statements or quotes on
      the website/mobile application. The liability of Paybycal for the accuracy
      or completeness of such third-party statements and quotes shall be
      excluded to the extent permitted by applicable law, rules and regulations.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Prohibited Activities
    </p>

    <p>
      User should not access or use the Website/Mobile Application for any
      purpose other than that for which Paybycal make the Website/Mobile
      Application available. The Website/Mobile Application may not be used in
      connection with any commercial endeavors except those that are
      specifically endorsed or approved by Paybycal.<br />
      <br />
      As a user of the Website/Mobile Application, you agree not to:<br />
      <br />
      1. Retrieve data or other content from the Website/Mobile Application to
      create or compile, directly or indirectly, a collection, compilation,
      database, or directory without written permission from Paybycal.<br />
      <br />
      2. Make any unauthorized use of the Website/Mobile Application, including
      collecting usernames and/or email addresses of users by electronic or
      other means for the purpose of sending unsolicited email, or creating user
      accounts by automated means or under false pretenses.<br />
      <br />
      3. Use a buying agent or purchasing agent to make purchases on the
      Website/Mobile Application.<br />
      <br />
      4. Use the Website/Mobile Application to advertise or offer to sell goods
      and services.<br />
      <br />
      5. Circumvent, disable, or otherwise interfere with security-related
      features of the Website/Mobile Application, including features that
      prevent or restrict the use or copying of any Content or enforce
      limitations on the use of the Website/Mobile Application and/or the
      Content contained therein.<br />
      <br />
      6. Engage in unauthorized framing of or linking to the Website/Mobile
      Application.<br />
      <br />
      7. Trick, defraud, or mislead Paybycal and other users, especially in any
      attempt to learn sensitive account information such as user passwords.<br />
      <br />
      8. Make improper use of Paybycal support services or submit false reports
      of abuse or misconduct.<br />
      <br />
      9. Engage in any automated use of the system, such as using scripts to
      send comments or messages, or using any data mining, robots, or similar
      data gathering and extraction tools.<br />
      <br />
      10. Interfere with, disrupt, or create an undue burden on the
      Website/Mobile Application or the networks or services connected to the
      Website/Mobile Application.<br />
      <br />
      11. Sell or otherwise transfer your profile.<br />
      <br />
      12. Use any information obtained from the Website/Mobile Application in
      order to harass, abuse, or harm another person.<br />
      <br />
      13. Use the Website/Mobile Application as part of any effort to compete
      with Paybycal or otherwise use the Website/Mobile Application and/or the
      Content for any revenue-generating endeavor or commercial enterprise.<br />
      <br />
      14. Decipher, decompile, disassemble, or reverse engineer any of the
      software comprising or in any way making up a part of the Website/Mobile
      Application.<br />
      <br />
      15. Attempt to bypass any measures of the Website/Mobile Application
      designed to prevent or restrict access to the Website/Mobile Application,
      or any portion of the Website/Mobile Application.<br />
      <br />
      16. Harass, annoy, intimidate, or threaten any of Paybycal employees or
      agents engaged in providing any portion of the Website/Mobile Application
      to you.<br />
      <br />
      17. Delete the copyright or other proprietary rights notice from any
      Content.<br />
      <br />
      18. Copy or adapt the Website/Mobile Application&rsquo;s software,
      including but not limited to Flash, PHP, HTML, JavaScript, Angular or any
      other code.<br />
      <br />
      19. Upload or transmit (or attempt to upload or to transmit) viruses,
      Trojan horses, or other material, including excessive use of capital
      letters and spamming (continuous posting of repetitive text), that
      interferes with any party&rsquo;s uninterrupted use and enjoyment of the
      Website/Mobile Application or modifies, impairs, disrupts, alters, or
      interferes with the use, features, functions, operation, or maintenance of
      the Website/Mobile Application.<br />
      <br />
      20. Upload or transmit (or attempt to upload or to transmit) any material
      that acts as a passive or active information collection or transmission
      mechanism, including without limitation, clear graphics interchange
      formats (&ldquo;gifs&rdquo;), web bugs, cookies, or other similar devices
      (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive
      collection mechanisms&rdquo; or &ldquo;pcms&rdquo;).<br />
      <br />
      21. Except as may be the result of standard search engine or Internet
      browser usage, use, launch, develop, or distribute any automated system,
      including without limitation, any spider, robot, cheat utility, scraper,
      or offline reader that accesses the Website/Mobile Application, or using
      or launching any unauthorized script or other software.<br />
      <br />
      22. Disparage, tarnish, or otherwise harm, in the opinion of the
      Website/Mobile Application.<br />
      <br />
      23. Use the Website/Mobile Application in a manner inconsistent with any
      applicable laws or regulations.<br />
      &nbsp;
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Limitation of Liability
    </p>

    <p>
      In consideration of being allowed to participate in the activities and
      structured diet programs offered on articles by Paybycal, and to use its
      structured diet programs and training schedule, you or any third party do
      hereby waive, release and forever discharge and hold harmless Paybycal.
      and its directors, consultants, officers, agents, and employees from any
      and all responsibility, liability, cost and expenses, including injuries
      or any direct, indirect, consequential, exemplary, incidental, special, or
      punitive damages, including lost profit, lost revenue, loss of data, or
      other damages, resulting from your participation in any activities, or
      your use of any structured diet programs offered on articles provided by
      Paybycal. You do also hereby release Paybycal its directors, consultants,
      officers, agents and employees from any responsibility or liability for
      any injury, damage or disorder (physical, metabolic, or otherwise) to
      yourself, or in any way arising out of or connected with your
      participation in any activities with Paybycal.<br />
      <br />
      You understand and you are aware that strength, flexibility, and aerobic
      exercise, including the use of equipment are a potentially hazardous
      activity. You also understand that fitness activities involve a risk of
      injury and even death, and that you are voluntarily participating in these
      activities and using equipment and machinery with knowledge of the risk
      involved. You hereby agree to expressly assume and accept any and all
      risks of injury or death related to said fitness activities. In addition,
      you certify that you are not minor, that is to say, you are 18 years of
      age or older. You do hereby further declare yourself to be physically
      sound and suffering from no condition, impairment, disease, infirmity or
      other illness that would affect nutrient metabolism or prevent your
      participation or use of equipment or machinery except as hereinafter
      stated.<br />
      <br />
      And You also agree that if you are suffering from any ailment or any
      medical condition you have to inform and produce relevant documents to
      Paybycal prior to signing of any document or agreement. You do hereby
      acknowledge that Paybycal has recommended you to obtain a
      physician&rsquo;s approval for your participation in an exercise/fitness
      activity or in the use of exercise equipment and machinery. You also
      acknowledge that Paybycal has recommended that you have a yearly or more
      frequent physical examination and consultation with your physician as to
      physical activity, exercise and use of exercise and training equipment so
      that you might have his/her recommendations concerning these fitness
      activities and use of equipment. You acknowledge that you have either had
      a physical examination and been given your physician&rsquo;s permission to
      participate, or that you have decided to participate in activity and use
      of equipment, machinery, and programs provided in Paybycal articles
      without the approval of your physician and do hereby assume all
      responsibility for your participation and activities, and utilization of
      equipment and machinery in your activities<br />
      <br />
      Paybycal diet/training programs are not meant to treat or manage any
      health condition. Always consult with your healthcare provider prior to
      adjusting your current style of eating or beginning any new diet and/or
      training plan. You understand that you are agreeing to the terms of the
      Company i.e., HELTHOFIT PVT LTD, having known that beforehand and
      understand that not following the instructions and structured diet
      programs in the articles provided entirely and regularly given by Paybycal
      will not produce 100% result.<br />
      <br />
      IN NO EVENT WILL PAYBYCAL OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
      TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
      EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
      PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE
      OF THE WEBSITE/MOBILE APPLICATION, EVEN IF PAYBYCAL HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Limitation of Warranties
    </p>

    <p>
      Content contained on the Website/Mobile Application is provided &ldquo;as
      is&rdquo; and &ldquo;as available&rdquo;. Paybycal and third-party Content
      providers give no guarantee, representation, warranty or condition,
      express or implied, statutory or otherwise, as to condition, satisfactory
      quality, performance, fitness for purpose, non-infringement,
      merchantability or otherwise in respect of Paybycal Website/Mobile
      Application or their Content. Without limiting the foregoing, Paybycal and
      third-party Content providers do not guarantee, represent or warrant the
      accuracy, timeliness, completeness, reliability or availability of the
      Website/Mobile Application or the information or results obtained from
      them (whether based on manual access, subscriptions, or otherwise), or
      that the Website/Mobile Application or downloads (e.g., PDF, pictures,
      podcasts, videos) from the Website/Mobile Application are virus-free or
      error-free. All such guarantees, representations, warranties and
      conditions are excluded, except to the extent that their exclusion is not
      permitted by applicable laws, rules and regulations.<br />
      <br />
      Paybycal and other Service Providers (without limiting the foregoing)
      disclaim all guarantees, representations, warranties and conditions with
      respect to the operation of the Platform and related App for Paybycal,
      express or implied, including, but not limited to, the implied guarantees,
      representations, warranties or conditions of merchantability, fitness for
      particular purpose and the non-infringement. Paybycal and the Service
      Provider disclaim all guarantees, representations, warranties or
      conditions that the Platform and related App are not interrupted or error
      free.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Contributions to PAYBYCAL
    </p>

    <p>
      You may provide Content to the Website/Mobile Application by using
      relevant functionalities on Paybycal, by logging-in, blogs, message
      boards, online forums, by e-mail or otherwise (&quot;Contributions&quot;).
      Furthermore, Paybycal may invite users to provide Contributions in own- or
      third-party surveys or polls and on a particular issue raised. If you
      provide Contributions to Paybycal, Paybycal is entitled, but not in any
      way obliged, to use and make available all or parts of your unmodified or
      modified Contributions on the Website/Mobile Application. Paybycal will
      not mention your name or contact details, unless you have agreed and you
      have logged in with an identified account. If you provide Contributions to
      Paybycal, you grant Paybycal all necessary rights, if any, or license to
      use, reproduce, distribute, modify, and publicly display your
      Contributions on the Website/Mobile Application for an unlimited period of
      time.<br />
      <br />
      By submitting a Contribution, you guarantee, represent and warrant that
      the Contribution complies with applicable laws, rules and regulations and
      in particular:
    </p>

    <ul>
      <li>
        Is legal, decent and truthful and not defamatory, unreliable, misleading
        or otherwise objectionable;
      </li>
      <li>
        That you own or have the necessary rights, licenses, consents and
        permissions, without the need for any permission from or payment to any
        other person or entity, to exploit, and to authorize Paybycal to
        exploit, such Contribution in all manners contemplated by these Terms of
        Use;
      </li>
      <li>
        Does not harm others, in particular minors, or infringe their personal
        rights.
      </li>
      <li>
        Your Contributions are not obscene, lewd, lascivious, filthy, violent,
        harassing, libelous, slanderous, or otherwise objectionable (as
        determined by us).
      </li>
      <li>
        Your Contributions do not ridicule, mock, disparage, intimidate, or
        abuse anyone.
      </li>
      <li>
        Your Contributions do not include any offensive comments that are
        connected to race, national origin, gender, sexual preference, or
        physical handicap.
      </li>
      <li>
        Your Contributions do not contain any material that solicits personal
        information from anyone under the age of 18 or exploits people under the
        age of 18 in a sexual or violent manner.
      </li>
      <li>
        Your Contributions do not violate any Central or State law concerning
        child pornography, or otherwise intended to protect the health or
        well-being of minors.
      </li>
    </ul>

    <p>Users must not provide Contributions which:</p>

    <ul>
      <li>
        Contain a virus, so-called Trojan Horse, or any other program that could
        damage data;
      </li>
      <li>
        Amount to advertising material or unsolicited (so-called
        &quot;spam&quot;) material;
      </li>
      <li>
        Contain inaccurate warnings of viruses, defects or similar material;
      </li>
      <li>
        Solicit or requests participation in any lottery, snowball system, chain
        letter, pyramid game or similar activity.
      </li>
    </ul>

    <p>
      You hereby agree to indemnify, defend, and hold Paybycal harmless from and
      against any and all losses, damages, liabilities and costs (including
      settlement costs and any legal or other fees and expenses for
      investigating or defending any actions or threatened actions) incurred by
      Paybycal in connection with any claim arising out of any breach by you of
      these Terms of Use or claims arising from your use of the Website/Mobile
      Application. You shall use your best efforts to cooperate with Paybycal in
      the defense of any claim. We reserve the right, at our own expense, to
      employ separate counsel and assume the exclusive defense and control of
      any matter otherwise subject to indemnification by you.<br />
      <br />
      Paybycal may deny you access to the Website/Mobile Application at any
      time, in particular if you breach any of the above provisions.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Intellectual Property Rights
    </p>

    <p>
      You may not allocate, distribute, or reproduce in any way any copyrighted
      material, Designs, trademarks or other Intellectual proprietary
      Information belonging to others without obtaining the prior written
      consent of the owner of such proprietary rights. It is the policy of
      Paybycal Website/Mobile Application to terminate user privileges who once
      infringes the copyright rights of others upon receipt of prompt
      notification to the Website/Mobile Application by the copyright owner or
      the copyright owner&#39;s legal agent/Advisor. Without limiting the
      foregoing, if you believe that your work has been copied and posted on the
      Paybycal services in a way that constitutes copyright infringement, please
      provide us with the following information:<br />
      <br />
      1. an electronic or physical signature of the person authorized to act on
      behalf of the owner of the copyright interest;<br />
      2. a description of the copyrighted work that you claim has been
      infringed;<br />
      3. a description of where the material that you claim is infringing is
      located on the website;<br />
      4. your address, telephone number, and email address;<br />
      5. a written statement by you that you have a good faith belief that the
      disputed use is not authorized by the copyright owner, its agent, or the
      law;<br />
      6. a statement by you, made under penalty of perjury, that the above
      information in your notice is accurate and that you are the copyright
      owner or authorized to act on the copyright owner&#39;s behalf.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Association / Links to Third Party
    </p>

    <p>
      Paybycal Website/Mobile Application may contain links to third party
      websites &quot;Linked Sites&quot; (&quot;Affiliates&quot; / &quot;Third
      Party Websites&quot;). These Linked Sites are not under the control of
      www.helthofit.com and the Website/Mobile Application is not responsible
      for the products of any Linked Site, including without limitation any link
      contained in a Linked Site, or any changes or updates to a Linked website.
      Such Third-Party Websites and Third-Party Content are not investigated,
      monitored, or checked for accuracy, appropriateness, or completeness by
      us, and we are not responsible for any Third-Party Websites accessed
      through the Website/Mobile Application or any Third-Party Content posted
      on, available through, or installed from the Website/Mobile Application,
      including the content, accuracy, offensiveness, opinions, reliability,
      privacy practices, or other policies of or contained in the Third-Party
      Websites or the Third-Party Content. Paybycal Website/Mobile Application
      is not responsible for web casting or any other form of transmission
      received from any Linked website nor is responsible if the Linked Site is
      not working appropriately. Inclusion of, linking to, or permitting the use
      or installation of any Third-Party Websites or any Third-Party Content
      does not imply approval or endorsement thereof by Paybycal. If you decide
      to leave the Website/Mobile Application and access the Third-Party
      Websites or to use or install any Third-Party Content, you do so at your
      own risk, and you should be aware these Terms and Conditions no longer
      govern. You are responsible for viewing and abiding by the privacy
      statements and terms of use posted at the Linked Sites. Any purchases you
      make through Third-Party Websites will be through other websites and from
      other companies, and we take no responsibility whatsoever in relation to
      such purchases which are exclusively between you and the applicable third
      party. You agree and acknowledge that we do not endorse the products or
      services offered on Third-Party Websites and you shall hold us harmless
      from any harm caused by your purchase of such products or services.
      Additionally, you shall hold us harmless from any losses sustained by you
      or harm caused to you relating to or resulting in any way from any
      Third-Party Content or any contact with Third-Party Websites.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Mobile Application License
    </p>

    <p>
      <u>Use License</u><br />
      <br />
      If you access Paybycal via a mobile application, then we grant you a
      revocable, non-exclusive, non-transferable, limited right to install and
      use the mobile application on wireless electronic devices owned or
      controlled by you, and to access and use the mobile application on such
      devices strictly in accordance with the terms and conditions of this
      mobile application license contained in these Terms and Conditions.<br />
      You shall not: 1. decompile, reverse engineer, disassemble, attempt to
      derive the source code of, or decrypt the application;<br />
      2. make any modification, adaptation, improvement, enhancement,
      translation, or derivative work from the application;<br />
      3. violate any applicable laws, rules, or regulations in connection with
      your access or use of the application;<br />
      4. remove, alter, or obscure any proprietary notice (including any notice
      of copyright or trademark) posted by us or the licensors of the
      application;<br />
      5. use the application for any revenue generating endeavor, commercial
      enterprise, or other purpose for which it is not designed or intended;<br />
      6. make the application available over a network or other environmental
      permitting access or use by multiple devices or users at the same time;<br />
      7. use the application for creating a product, service, or software that
      is, directly or indirectly, competitive with or in any way a substitute
      for the application;<br />
      8. use the application to send automated queries to any website or to send
      any unsolicited commercial e-mail; or<br />
      9. use any proprietary information or any of Paybycal interfaces or other
      intellectual property of Paybycal in the design, development, manufacture,
      licensing, or distribution of any applications, accessories, or devices
      for use with the application.<br />
      <br />
      The following terms apply when you use a mobile application obtained from
      either the Apple Store or Google Play (&ldquo;App Distributor&rdquo;) to
      access the Website/Mobile Application:<br />
      <br />
      1. the license granted to you for Paybycal mobile application is limited
      to a non-transferable license to use the application on a device that
      utilizes the Apple iOS or Android operating systems, as applicable, and in
      accordance with the usage rules set forth in the applicable App
      Distributor&rsquo;s terms of service;<br />
      2. Paybycal is responsible for providing any maintenance and support
      services with respect to the mobile application as specified in the terms
      and conditions of this agreement.<br />
      3. Application license contained in these Terms and Conditions or as
      otherwise required under applicable law, and you acknowledge that each App
      Distributor has no obligation whatsoever to furnish any maintenance and
      support services with respect to the mobile application;<br />
      4. In the event of any failure of the mobile application to conform to any
      applicable warranty, you may notify the applicable App Distributor, and
      the App Distributor, in accordance with its terms and policies, may refund
      the purchase price, if any, paid for the mobile application, and to the
      maximum extent permitted by applicable law, the App Distributor will have
      no other warranty obligation whatsoever with respect to the mobile
      application;<br />
      5. You represent and warrant that: (a) you are not located in a country
      that is subject to an Indian government embargo, or that has been
      designated by the Indian government as a &ldquo;terrorist
      supporting&rdquo; country and (b) you are not listed on any Indian
      government list of prohibited or restricted parties;<br />
      6. You must comply with applicable third-party terms of agreement when
      using the mobile application, e.g., if you have a VoIP application, then
      you must not be in violation of their wireless data service agreement when
      using the mobile application; and<br />
      7. You acknowledge and agree that the App Distributors are third-party
      beneficiaries of the terms and conditions in this mobile application
      license contained in these Terms and Conditions, and that each App
      Distributor will have the right (and will be deemed to have accepted the
      right) to enforce the terms and conditions in this mobile application
      license contained in these Terms and Conditions against you as a
      third-party beneficiary thereof.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Website / Mobile Application Management
    </p>

    <p>
      Paybycal reserves the right, but not the obligation, to:<br />
      <br />
      1. monitor the Website/Mobile Application for violations of these Terms
      and Conditions;<br />
      2. take appropriate legal action against anyone who, in the sole
      discretion of Paybycal, violates the law or these Terms and Conditions,
      including without limitation, reporting such user to law enforcement
      authorities;<br />
      3. Paybycal, in its sole discretion and without limitation, refuse,
      restrict access to, limit the availability of, or disable (to the extent
      technologically feasible) any of your Contributions or any portion
      thereof;<br />
      4. Paybycal, in its sole discretion and without limitation, notice, or
      liability, to remove from the Website/Mobile Application or otherwise
      disable all files and content that are excessive in size or are in any way
      burdensome to systems of Paybycal; and<br />
      5. otherwise manage the Website/Mobile Application in a manner designed to
      protect rights and property of Paybycal and to facilitate the proper
      functioning of the Website/Mobile Application.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Guidelines for Reviews
    </p>

    <p>
      Paybycal may provide you areas on the Website/Mobile Application to leave
      reviews or ratings. When posting a review, you must comply with the
      following criteria:<br />
      <br />
      1. you should have firsthand experience with the person/entity being
      reviewed;<br />
      2. your reviews should not contain offensive profanity, or abusive,
      racist, offensive, or hate language;<br />
      3. your reviews should not contain discriminatory references based on
      religion, race, gender, national origin, age, marital status, sexual
      orientation, or disability;<br />
      4. your reviews should not contain references to illegal activity;<br />
      5. you should not be affiliated with competitors if posting negative
      reviews;<br />
      6. you should not make any conclusions as to the legality of conduct;<br />
      7. you may not post any false or misleading statements; and<br />
      8. you may not organize a campaign encouraging others to post reviews,
      whether positive or negative.<br />
      <br />
      Paybycal may accept, reject, or remove reviews in its sole discretion.
      Paybycal have absolutely no obligation to screen reviews or to delete
      reviews, even if anyone considers reviews objectionable or inaccurate.
      Reviews are not endorsed by Paybycal, and do not necessarily represent its
      opinions or the views of any of our affiliates or partners. We do not
      assume liability for any review or for any claims, liabilities, or losses
      resulting from any review. By posting a review, you hereby grant to
      Paybycal a perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
      assignable, and sub licensable right and license to reproduce, modify,
      translate, transmit by any means, display, perform, and/or distribute all
      content relating to reviews.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Privacy</p>

    <p>
      You should carefully read our full Privacy Policy before deciding to
      become a User as it is hereby incorporated into this Agreement by
      reference and governs our treatment of any information, including
      personally identifiable information you submit to Paybycal. Please note
      that certain information, statements, data, and content (such as
      photographs) which you may submit to the Website/Mobile Application, or
      groups, or are likely to, reveal your gender, ethnic origin, nationality,
      age, and/or other personal information about you. You acknowledge that
      your submission of any information, statements, data, and content to us is
      voluntary on your part.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Disclaimer</p>

    <p>
      DO NOT RELY ON PAYBYCAL WEBSITE/MOBILE APPLICATION, ANY INFORMATION
      THEREIN, OR ITS CONTINUATION. PAYBYCAL PROVIDE THE PLATFORM AND
      INFORMATION ABOUT ITS SERVICES WHICH ARE ON &ldquo;AS IS&rdquo; AND
      &ldquo;AS AVAILABLE&rdquo; BASIS. WEBSITE/MOBILE APPLICATION DOES NOT
      CONTROL OR VET USER GENERATED CONTENT FOR ACCURACY. PAYBYCAL DO NOT
      PROVIDE ANY EXPRESS WARRANTIES OR REPRESENTATIONS.<br />
      <br />
      TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, HELTHOFIT
      DISCLAIMS ANY AND ALL IMPLIED WARRANTIES AND REPRESENTATIONS, INCLUDING,
      WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, TITLE, ACCURACY OF DATA, AND NON-INFRINGEMENT. IF YOU
      ARE DISSATISFIED OR HARMED BY THE WEBSITE OR ANYTHING RELATED TO THE
      WEBSITE, YOU MAY CLOSE YOUR ACCOUNT AND TERMINATE THIS AGREEMENT IN
      ACCORDANCE WITH &ldquo;TERMINATION&rdquo; CLAUSE OF THIS AGREEMENT AND
      SUCH TERMINATION SHALL BE YOUR SOLE AND EXCLUSIVE REMEDY.<br />
      <br />
      PAYBYCAL IS NOT RESPONSIBLE, AND MAKES NO REPRESENTATIONS OR WARRANTIES
      FOR THE DELIVERY OF ANY MESSAGE (SUCH AS INMAILS, POSTING OF ANSWERS OR
      TRANSMISSION OF ANY OTHER USER GENERATED CONTENT) SENT THROUGH THE WEBSITE
      TO ANYONE. IN ADDITION, PAYBYCAL NEITHER WARRANT NOR REPRESENT USE OF USER
      FOR THE SERVICES AND WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES. ANY
      MATERIAL, SERVICE, OR TECHNOLOGY DESCRIBED OR USED ON THE WEBSITE MAY BE
      SUBJECT TO INTELLECTUAL PROPERTY RIGHTS OWNED BY THIRD PARTIES WHO HAVE
      LICENSED SUCH MATERIAL, SERVICE, OR TECHNOLOGY TO HELTHOFIT.<br />
      <br />
      THE WEBSITE DOES NOT HAVE ANY OBLIGATION TO VERIFY THE IDENTITY OF THE
      PERSONS SUBSCRIBING TO ITS SERVICES, NOR DOES IT HAVE ANY OBLIGATION TO
      MONITOR THE USE OF ITS SERVICES BY OTHER USERS OF THE COMMUNITY;
      THEREFORE, ALTFIT DISCLAIMS ALL LIABILITY FOR IDENTITY THEFT OR ANY OTHER
      MISUSE OF YOUR IDENTITY OR INFORMATION.<br />
      <br />
      THE WEBSITE DOES NOT GUARANTEE THAT THE SERVICES IT PROVIDES WILL FUNCTION
      WITHOUT INTERRUPTION OR ERRORS IN FUNCTIONING. IN PARTICULAR, THE
      OPERATION OF THE SERVICES MAY BE INTERRUPTED DUE TO MAINTENANCE, UPDATES,
      OR SYSTEM OR NETWORK FAILURES. PAYBYCAL DISCLAIMS ALL LIABILITY FOR
      DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS IN FUNCTIONING.
      FURTHERMORE, IT DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING,
      IMPOSSIBILITY OF ACCESS, OR POOR USE CONDITIONS OF THE WEBSITE DUE TO
      INAPPROPRIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE
      PROVIDERS, TO THE SATURATION OF THE INTERNET NETWORK, AND FOR ANY OTHER
      REASON.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Termination</p>

    <p>
      This Agreement shall remain in full force and effect while you use the
      Website/Mobile Application. However, the Website/Mobile Application
      reserves the right to terminate access to certain areas or features of the
      Website/Mobile Application at any time for any reason, with or without
      notice.&nbsp;<br />
      <br />
      Paybycal also reserves the universal right to deny access to particular
      users to any/all of its services/activities/training program/content
      without any prior notice/explanation in order to protect the interests of
      the website and/or other visitors to the Website/Mobile Application.
      Paybycal reserves the right to limit, deny or create different access to
      the Website/Mobile Application and its features with respect to different
      user(s), or to change any of the features or introduce new features
      without prior notice. The website withholds the right to temporary or
      permanent termination of any user for any of the following reasons:<br />
      <br />
      (a)&nbsp; If it concludes that the user(s) have provided any false
      information in connection with the member account to the website, or are
      engaged in fraudulent or illegal activities.&nbsp;<br />
      (b) The user(s) breach any provisions of the terms and conditions of use
      agreement and/or Agreement of website.<br />
      &nbsp;(c) Utilize the Website/Mobile Application to send spam messages or
      repeatedly publish the same product or service information.<br />
      &nbsp;(d) Post any material to members that are not related to
      international trade or business cooperation.<br />
      (e) Impersonate or unlawfully use other companies name to post information
      or conduct business of any form.<br />
      (f) Any unauthorized access, use, modification, or control of the website
      data base, network or related services
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Indemnification</p>

    <p>
      You agree to defend, indemnify, and hold Paybycal harmless, including its
      subsidiaries, affiliates, and all of Paybycal respective officers, agents,
      partners, and employees, from and against any loss, damage, liability,
      claim, or demand, including reasonable attorneys&rsquo; fees and expenses,
      made by any third party due to or arising out of:<br />
      1.&nbsp;&nbsp; &nbsp;your Contributions;<br />
      2.&nbsp;&nbsp; &nbsp;use of the Website/Mobile Application;<br />
      3.&nbsp;&nbsp; &nbsp;breach of these Terms and Conditions;<br />
      4.&nbsp;&nbsp; &nbsp;any breach of your representations and warranties set
      forth in these Terms and Conditions;&nbsp;<br />
      5.&nbsp;&nbsp; &nbsp;your violation of the rights of a third party,
      including but not limited to intellectual property rights; or<br />
      6.&nbsp;&nbsp; &nbsp;any overt harmful act toward any other user of the
      Website/Mobile Application with whom you connected via the Website/Mobile
      Application. Notwithstanding the foregoing, we reserve the right, at your
      expense, to assume the exclusive defense and control of any matter for
      which you are required to indemnify Paybycal, and you agree to cooperate,
      at your expense, with Paybycal defense of such claims. Paybycal will use
      reasonable efforts to notify you of any such claim, action, or proceeding
      which is subject to this indemnification upon becoming aware of it.<br />
      &nbsp;
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Electronic Communications, Transactions, And Signatures
    </p>

    <p>
      Visiting the Website/Mobile Application, sending us emails, and completing
      online forms constitute electronic communications. You consent to receive
      electronic communications, and you agree that all agreements, notices,
      disclosures, and other communications we provide to you electronically,
      via email and on the Website/Mobile Application, satisfy any legal
      requirement that such communication be in writing.<br />
      <br />
      YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
      AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
      RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY PAYBYCAL OR VIA THE
      WEBSITE/MOBILE APPLICATION.<br />
      <br />
      You hereby waive any rights or requirements under any statutes,
      regulations, rules, ordinances, or other laws in any jurisdiction which
      require an original signature or delivery or retention of non-electronic
      records, or to payments or the granting of credits by any means other than
      electronic means.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>User Data</p>

    <p>
      Paybycal will maintain certain data that you transmit to the
      Website/Mobile Application for the purpose of managing the performance of
      the Website/Mobile Application, as well as data relating to your use of
      the Website/Mobile Application. Although we encrypt and perform regular
      routine backups of data, you are solely responsible for all data that you
      transmit or that relates to any activity you have undertaken using the
      Website/Mobile Application. You agree that we shall have no liability to
      you for any loss or corruption of any such data, and you hereby waive any
      right of action against us arising from any such loss or corruption of
      such data.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      General provisions
    </p>

    <p>
      If a provision of these Terms of Use is or becomes illegal, invalid or
      unenforceable in any country, that will not affect the legality, validity
      or enforceability in that country of any other provision or in other
      jurisdictions of that or any other provisions of these Terms of Use.
    </p>

    <p>
      Certain pages or areas on the Website/Mobile Application may contain
      additional terms, conditions, disclosures or disclaimers
      (&ldquo;Additional Terms&rdquo;). In the event of a conflict between these
      Terms of Use and the Additional Terms, the Additional Terms will govern
      for those pages or areas.
    </p>

    <p>
      Rights of Paybycal under these Terms of Use may be exercised as often as
      necessary. They are cumulative and not exclusive of rights or remedies
      provided by law, rules and regulations. They may be waived only in writing
      and specifically. Delay in the exercise or non-exercise of any such right
      is not a waiver of that right. An express or implied waiver of any of the
      provisions or of any breach of or default in performing any of the
      provisions of these Terms of Use will not constitute a continuing waiver
      and that waiver will not prevent the waiving party from subsequently
      enforcing any of the provisions of these Terms of Use not waived or from
      acting on any subsequent breach of or default by the other party under any
      of the provisions of these Terms of Use.<br />
      Any notice or other communication given under or in connection with these
      Terms of Use will be in English. All other documents provided under or in
      connection with these Terms of Use will be in English. A document in
      another language must be accompanied by a certified English translation.
      In such a situation the English translation will prevail, unless the
      document is a statutory or other official document.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Compliance with Laws
    </p>

    <p>
      You are responsible for complying with all applicable laws, rules and
      regulations, all third-party rights and all Paybycal policies. You shall
      not use the website in a manner that violates such laws, rules and
      regulations, third parties&#39; rights or any Paybycal policies or in a
      manner that is deceptive, unethical, false or misleading. For avoidance of
      doubt, you shall not distribute or provide access to applications you
      develop in contravention of Indian laws.
    </p>

    <p>
      This Agreement shall be governed by and construed in accordance with
      Indian Law and you hereby submit to the exclusive jurisdiction of the
      Mumbai, Maharashtra, India Courts.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>Non-Waiver</p>

    <p>
      Any forbearance or failure by Paybycal to enforce a provision to which you
      are subject shall not affect our right to require such performance at any
      subsequent time, nor shall the waiver or forbearance by Paybycal of any
      breach of any provisions of the agreement herein be taken to be or held to
      be a waiver of the provision or provisions itself or themselves.
    </p>

    <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
      Statute of Limitations
    </p>

    <p>
      User and Paybycal agree that any cause of action arising out of or related
      to these Services must commence within one (1) month after the cause of
      action arose; otherwise, such cause of action is permanently barred. If
      you do not agree with any of our Terms of Uses mentioned above, please do
      not read the material on any of our pages or do not accept Paybycal
      services.&nbsp;
    </p>

    <p>
      Paybycal Website/Mobile Application includes content relating to Helthofit
      Pvt Ltd and its businesses. www.paybycal.com is provided by Helthofit Pvt
      Ltd having registered office at BLD NO 17, Flat No 102, Rose Nagar CHS,
      Naigaon West, Palghar 401207.<br />
      &nbsp;
    </p>

    <p>
      Date Last Modified<br />
      These Terms of Use were last modified August 24, 2022.
    </p>

    </div>
    <FooterTwo />
    </>
  )
}
